
import React, { useState, useEffect } from 'react';

function App() {
  const [message, setMessage] = useState('Loading...'); // 서버에서 받은 메시지를 저장할 상태

  // 서버에서 데이터를 가져오는 fetch 요청
  useEffect(() => {
    fetch('http://localhost:3000/api/message') // 프록시 설정이 되어 있다면 상대 경로 사용
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => setMessage(data.message)) // 성공적으로 데이터를 받아오면 상태 업데이트
      .catch((error) => {
        console.error('Fetch error:', error); // 에러 로그 출력
        setMessage('Error: Unable to fetch message.'); // 에러 상태 업데이트
      });
  }, []); // 빈 배열은 이 요청을 컴포넌트가 처음 렌더링될 때만 실행

  return (
    <div className="App">
      <h1>React + Express 샘플</h1>
      <p>{message}</p> {/* 서버로부터 받은 메시지를 화면에 표시 */}
    </div>
  );
}

export default App;

